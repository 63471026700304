const G27SpreadInfo = {
  sheetName: 'G27',
  headerRowCount: 2,
  headerColumnCount: 9,
  headerColumns: [
    {
      index: 0,
      name: '序号',
      width: '110'
    },
    {
      index: 1,
      name: '材料名称及规格',
      width: '100',
      children: [
        {
          index: 1,
          name: '工程部位',
          width: '150'
        },
        {
          index: 2,
          name: '材料名称',
          width: '200'
        }
      ]
    },
    {
      index: 3,
      name: '单位',
      width: '60'
    },
    {
      index: 4,
      name: '合同总用量',
      width: '110'
    },
    {
      index: 5,
      name: '本年计划用量',
      width: '110'
    },
    {
      index: 6,
      name: '本月计划用量',
      width: '110'
    },
    {
      index: 7,
      name: '本月计划年度累计',
      width: '120'
    },
    {
      index: 8,
      name: '自开工计划累计',
      width: '120'
    }
  ]
}

export default G27SpreadInfo
